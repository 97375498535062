import { getUserDisplayName } from '~/common/helpers';
import * as Utilities from '~/common/utilities';
import { App } from '~/graphql/types.generated';
import { ProfileUser } from '~/scenes/ProfileScene/types';

import type { meta } from '*.mdx'; // ~/types/mdx.d.ts

const defaultImage = Utilities.getAssetUrl('/static/share/generic.jpg');
const defaultAppIcon = Utilities.getAssetUrl('/static/brand/square-512x512.png');

const defaultMetaTags = {
  title: <title>Expo</title>,
  twitterTitle: <meta name="twitter:title" content="Expo" />,
  facebookTitle: <meta property="og:title" content="Expo" />,
  description: (
    <meta
      name="description"
      content="Expo is an open-source platform for making universal native apps for Android, iOS, and the web with JavaScript and React."
    />
  ),
  twitterDescription: (
    <meta
      name="twitter:description"
      content="Expo is an open-source platform for making universal native apps for Android, iOS, and the web with JavaScript and React."
    />
  ),
  facebookDescription: (
    <meta
      property="og:description"
      content="Expo is an open-source platform for making universal native apps for Android, iOS, and the web with JavaScript and React."
    />
  ),
  image: <meta property="og:image" content={defaultImage} />,
  imageURL: <meta property="og:image:url" content={defaultImage} />,
  imageSecureURL: <meta property="og:image:secure_url" content={defaultImage} />,
  twitterImage: <meta name="twitter:image" content={defaultImage} />,
  appIcon: <link rel="apple-touch-icon" href={defaultAppIcon} />,
  msImage: <meta name="msapplication-TileImage" content={defaultAppIcon} />,
};

export type ProjectMeta = Pick<App, 'icon' | 'name'>;

export type PostMetadata = typeof meta;

export type NewsletterMetadata = {
  title: string;
  description: string;
  image?: string;
};

type Props = {
  pageName?: string;
  tagName?: string;
  experienceFullName?: string;
  username?: string;
  query?: string;
  user?: ProfileUser;
  project?: ProjectMeta;
  changelogMetadata?: PostMetadata;
  newsletterMetadata?: NewsletterMetadata;
};

const _copy = {
  default: () => defaultMetaTags,
  // error is defined separately so we can match and noindex these pages
  error: () => defaultMetaTags,
  'launch-party': () => {
    const title = `Launch Party — Expo`;
    const description = `A week of feature announcements and community events.`;

    const image = Utilities.getAssetUrl('/static/launch-party/og-image.png');

    return {
      ...defaultMetaTags,
      title: <title>{title}</title>,
      twitterTitle: <meta name="twitter:title" content={title} />,
      facebookTitle: <meta property="og:title" content={title} />,
      description: <meta name="description" content={description} />,
      twitterDescription: <meta name="twitter:description" content={description} />,
      facebookDescription: <meta property="og:description" content={description} />,
      image: <meta property="og:image" content={image} />,
      imageURL: <meta property="og:image:url" content={image} />,
      imageSecureURL: <meta property="og:image:secure_url" content={image} />,
      twitterImage: <meta name="twitter:image" content={image} />,
    };
  },
  graphiql: () => {
    return {
      ...defaultMetaTags,
      title: <title>GraphiQL — Expo</title>,
    };
  },
  settings: (props: Props) => {
    return {
      ...defaultMetaTags,
      title: <title>{`${props.pageName ?? 'Settings'} — Expo`}</title>,
    };
  },
  dashboard: (props: Props) => {
    const title = `${props.pageName ?? 'Dashboard'} — Expo`;
    const description = `View your recent activity and manage your resources with the Expo dashboard`;

    return {
      ...defaultMetaTags,
      title: <title>{title}</title>,
      twitterTitle: <meta name="twitter:title" content={title} />,
      facebookTitle: <meta property="og:title" content={title} />,
      description: <meta name="description" content={description} />,
      twitterDescription: <meta name="twitter:description" content={description} />,
      facebookDescription: <meta property="og:description" content={description} />,
    };
  },
  createOrganization: () => {
    return {
      ...defaultMetaTags,
      title: <title>Create Organization — Expo</title>,
    };
  },
  about: () => {
    const image = Utilities.getAssetUrl('/static/share/about.jpg');
    return {
      ...defaultMetaTags,
      title: <title>About — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="About — Expo" />,
      facebookTitle: <meta property="og:title" content="About — Expo" />,
      description: <meta name="description" content="Learn more about our company." />,
      twitterDescription: (
        <meta name="twitter:description" content="Learn more about our company." />
      ),
      facebookDescription: (
        <meta property="og:description" content="Learn more about our company." />
      ),
      image: <meta property="og:image" content={image} />,
      imageURL: <meta property="og:image:url" content={image} />,
      imageSecureURL: <meta property="og:image:secure_url" content={image} />,
      twitterImage: <meta name="twitter:image" content={image} />,
    };
  },
  builds: (props: Props) => {
    return {
      ...defaultMetaTags,
      title: (
        <title>
          {`Builds — ${
            props.experienceFullName ? props.experienceFullName : props.username
          } — Expo`}
        </title>
      ),
    };
  },
  build: (props: Props) => {
    return {
      ...defaultMetaTags,
      title: <title>{`Build Details — ${props.experienceFullName} — Expo`}</title>,
    };
  },
  easBuildStatus: () => {
    return {
      ...defaultMetaTags,
      title: <title>Service Status — EAS Build — Expo</title>,
    };
  },
  support: () => {
    const image = Utilities.getAssetUrl('/static/share/support.jpg');
    return {
      ...defaultMetaTags,
      title: <title>Get Support — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Get Support — Expo" />,
      facebookTitle: <meta property="og:title" content="Get Support — Expo" />,
      description: (
        <meta
          name="description"
          content="If your team needs dedicated support we can connect you to uniquely qualified and
            skilled Expo developers."
        />
      ),
      twitterDescription: (
        <meta
          name="twitter:description"
          content="If your team needs dedicated support we can connect you to uniquely qualified and
            skilled Expo developers."
        />
      ),
      facebookDescription: (
        <meta
          property="og:description"
          content="If your team needs dedicated support we can connect you to uniquely qualified and
            skilled Expo developers."
        />
      ),
      image: <meta property="og:image" content={image} />,
      imageURL: <meta property="og:image:url" content={image} />,
      imageSecureURL: <meta property="og:image:secure_url" content={image} />,
      twitterImage: <meta name="twitter:image" content={image} />,
    };
  },
  'support-terms': () => {
    return {
      ...defaultMetaTags,
      title: <title>Support Terms — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Support Terms — Expo" />,
      facebookTitle: <meta property="og:title" content="Support Terms — Expo" />,
      description: <meta name="description" content="Learn more about Expo's support policy." />,
      twitterDescription: (
        <meta name="twitter:description" content="Learn more about Expo's support policy." />
      ),
      facebookDescription: (
        <meta property="og:description" content="Learn more about Expo's support policy." />
      ),
    };
  },
  contact: () => {
    return {
      ...defaultMetaTags,
      title: <title>Contact Us — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Contact Us — Expo" />,
      facebookTitle: <meta property="og:title" content="Contact Us — Expo" />,
      description: <meta name="description" content="Get in touch with our team." />,
      twitterDescription: <meta name="twitter:description" content="Get in touch with our team." />,
      facebookDescription: <meta property="og:description" content="Get in touch with our team." />,
    };
  },
  pricing: () => {
    const image = Utilities.getAssetUrl('/static/share/pricing.jpg');
    return {
      ...defaultMetaTags,
      title: <title>Expo Application Services (EAS) Pricing</title>,
      twitterTitle: <meta name="twitter:title" content="Expo Application Services (EAS) Pricing" />,
      facebookTitle: <meta property="og:title" content="Expo Application Services (EAS) Pricing" />,
      description: (
        <meta name="description" content="Ship your next release with speed and confidence" />
      ),
      twitterDescription: (
        <meta
          name="twitter:description"
          content="Ship your next release with speed and confidence"
        />
      ),
      facebookDescription: (
        <meta
          property="og:description"
          content="Ship your next release with speed and confidence"
        />
      ),
      image: <meta property="og:image" content={image} />,
      imageURL: <meta property="og:image:url" content={image} />,
      imageSecureURL: <meta property="og:image:secure_url" content={image} />,
      twitterImage: <meta name="twitter:image" content={image} />,
    };
  },
  billing: () => {
    return {
      ...defaultMetaTags,
      title: <title>Billing - Expo</title>,
    };
  },
  brand: () => {
    return {
      ...defaultMetaTags,
      title: <title>Brand, Assets, Styles — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Brand, Assets, Styles — Expo" />,
      facebookTitle: <meta property="og:title" content="Brand, Assets, Styles — Expo" />,
      description: (
        <meta name="description" content="Get Expo brand assets and styles for any use." />
      ),
      twitterDescription: (
        <meta name="twitter:description" content="Get Expo brand assets and styles for any use." />
      ),
      facebookDescription: (
        <meta property="og:description" content="Get Expo brand assets and styles for any use." />
      ),
    };
  },
  resetpassword: () => {
    return {
      ...defaultMetaTags,
      title: <title>Reset Your Password — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Reset Your Password — Expo" />,
      facebookTitle: <meta property="og:title" content="Reset Your Password — Expo" />,
      description: (
        <meta name="description" content="Reset your password for your Expo account here." />
      ),
      twitterDescription: (
        <meta
          name="twitter:description"
          content="Reset your password for your Expo account here."
        />
      ),
      facebookDescription: (
        <meta property="og:description" content="Reset your password for your Expo account here." />
      ),
    };
  },
  login: () => {
    return {
      ...defaultMetaTags,
      title: <title>Log In — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Log In — Expo" />,
      facebookTitle: <meta property="og:title" content="Log In — Expo" />,
      description: <meta name="description" content="Log in to your Expo account." />,
      twitterDescription: (
        <meta name="twitter:description" content="Log in to your Expo account." />
      ),
      facebookDescription: (
        <meta property="og:description" content="Log in to your Expo account." />
      ),
    };
  },
  signup: () => {
    return {
      ...defaultMetaTags,
      title: <title>Sign Up — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Sign Up — Expo" />,
      facebookTitle: <meta property="og:title" content="Sign Up — Expo" />,
      description: <meta name="description" content="Create an account for Expo here." />,
      twitterDescription: (
        <meta name="twitter:description" content="Create an account for Expo here." />
      ),
      facebookDescription: (
        <meta property="og:description" content="Create an account for Expo here." />
      ),
    };
  },
  ssologin: () => {
    return {
      ...defaultMetaTags,
      title: <title>Log In With SSO — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Log In with SSO — Expo" />,
      facebookTitle: <meta property="og:title" content="Log In with SSO — Expo" />,
      description: <meta name="description" content="Log in with SSO to your Expo account." />,
      twitterDescription: (
        <meta name="twitter:description" content="Log in with SSO to your Expo account." />
      ),
      facebookDescription: (
        <meta property="og:description" content="Log in with SSO to your Expo account." />
      ),
    };
  },
  ssosignup: () => {
    return {
      ...defaultMetaTags,
      title: <title>Sign Up With SSO — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Sign Up with SSO — Expo" />,
      facebookTitle: <meta property="og:title" content="Sign Up with SSO — Expo" />,
      description: <meta name="description" content="Sign up with SSO for Expo here." />,
      twitterDescription: (
        <meta name="twitter:description" content="Sign up with SSO for Expo here." />
      ),
      facebookDescription: (
        <meta property="og:description" content="Sign up with SSO for Expo here." />
      ),
    };
  },
  careers: () => {
    return {
      ...defaultMetaTags,
      title: <title>We Are Hiring! — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="We Are Hiring! — Expo" />,
      facebookTitle: <meta property="og:title" content="We Are Hiring! — Expo" />,
      description: (
        <meta
          name="description"
          content="Create the way people write and distribute application software."
        />
      ),
      twitterDescription: (
        <meta
          name="twitter:description"
          content="Create the way people write and distribute application software."
        />
      ),
      facebookDescription: (
        <meta
          property="og:description"
          content="Create the way people write and distribute application software."
        />
      ),
    };
  },
  position: (props: Props) => {
    return {
      ...defaultMetaTags,
      title: <title>{props.pageName} — Expo</title>,
      twitterTitle: <meta name="twitter:title" content={`${props.pageName} — Expo`} />,
      facebookTitle: <meta property="og:title" content={`${props.pageName} — Expo`} />,
      description: (
        <meta
          name="description"
          content="Create the way people write and distribute application software."
        />
      ),
      twitterDescription: (
        <meta
          name="twitter:description"
          content="Create the way people write and distribute application software."
        />
      ),
      facebookDescription: (
        <meta
          property="og:description"
          content="Create the way people write and distribute application software."
        />
      ),
    };
  },
  privacy: () => {
    return {
      ...defaultMetaTags,
      title: <title>Privacy Policy — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Privacy Policy — Expo" />,
      facebookTitle: <meta property="og:title" content="Privacy Policy — Expo" />,
      description: <meta name="description" content="Learn more about Expo's privacy policy." />,
      twitterDescription: (
        <meta name="twitter:description" content="Learn more about Expo's privacy policy." />
      ),
      facebookDescription: (
        <meta property="og:description" content="Learn more about Expo's privacy policy." />
      ),
    };
  },
  privacyexplained: () => {
    return {
      ...defaultMetaTags,
      title: <title>Privacy Explained — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Privacy Explained — Expo" />,
      facebookTitle: <meta property="og:title" content="Privacy Explained — Expo" />,
      description: (
        <meta
          name="description"
          content="Privacy of the Expo Platform and Expo Services Explained."
        />
      ),
      twitterDescription: (
        <meta
          name="twitter:description"
          content="Privacy of the Expo Platform and Expo Services Explained."
        />
      ),
      facebookDescription: (
        <meta
          property="og:description"
          content="Privacy of the Expo Platform and Expo Services Explained."
        />
      ),
    };
  },
  security: () => {
    return {
      ...defaultMetaTags,
      title: <title>Security & Compliance — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Security & Compliance — Expo" />,
      facebookTitle: <meta property="og:title" content="Security & Compliance — Expo" />,
      description: (
        <meta name="description" content="Learn more about Expo's security and compliance." />
      ),
      twitterDescription: (
        <meta
          name="twitter:description"
          content="Learn more about Expo's security and compliance."
        />
      ),
      facebookDescription: (
        <meta
          property="og:description"
          content="Learn more about Expo's security and compliance."
        />
      ),
    };
  },
  subprocessors: () => {
    return {
      ...defaultMetaTags,
      title: <title>Subprocessors — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Subprocessors — Expo" />,
      facebookTitle: <meta property="og:title" content="Subprocessors — Expo" />,
      description: (
        <meta
          name="description"
          content="List of vendors/data subprocessors used in providing Expo services."
        />
      ),
      twitterDescription: (
        <meta
          name="twitter:description"
          content="List of vendors/data subprocessors used in providing Expo services."
        />
      ),
      facebookDescription: (
        <meta
          property="og:description"
          content="List of vendors/data subprocessors used in providing Expo services."
        />
      ),
    };
  },
  terms: () => {
    return {
      ...defaultMetaTags,
      title: <title>Terms of Service — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Terms of Service — Expo" />,
      facebookTitle: <meta property="og:title" content="Terms of Service — Expo" />,
      description: <meta name="description" content="Learn more about Expo's terms of service." />,
      twitterDescription: (
        <meta name="twitter:description" content="Learn more about Expo's terms of service." />
      ),
      facebookDescription: (
        <meta property="og:description" content="Learn more about Expo's terms of service." />
      ),
    };
  },
  guidelines: () => {
    return {
      ...defaultMetaTags,
      title: <title>Community Guidelines — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Community Guidelines — Expo" />,
      facebookTitle: <meta property="og:title" content="Community Guidelines — Expo" />,
      description: (
        <meta name="description" content="Learn more about Expo's community guidelines." />
      ),
      twitterDescription: (
        <meta name="twitter:description" content="Learn more about Expo's community guidelines." />
      ),
      facebookDescription: (
        <meta property="og:description" content="Learn more about Expo's community guidelines." />
      ),
    };
  },
  tools: () => {
    return {
      ...defaultMetaTags,
      title: <title>Get Expo Tools — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Get Expo Tools — Expo" />,
      facebookTitle: <meta property="og:title" content="Get Expo Tools — Expo" />,
      description: <meta name="description" content="Get the Expo development tools for free." />,
      twitterDescription: (
        <meta name="twitter:description" content="Get the Expo development tools for free." />
      ),
      facebookDescription: (
        <meta property="og:description" content="Get the Expo development tools for free." />
      ),
    };
  },
  consultants: () => {
    return {
      ...defaultMetaTags,
      title: <title>Consultants — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Consultants — Expo" />,
      facebookTitle: <meta property="og:title" content="Consultants — Expo" />,
      description: <meta name="description" content="Get help building your next project." />,
      twitterDescription: (
        <meta name="twitter:description" content="Get help building your next project." />
      ),
      facebookDescription: (
        <meta property="og:description" content="Get help building your next project." />
      ),
    };
  },
  accountSettings: () => ({
    ...defaultMetaTags,
    title: <title>Account Settings — Expo</title>,
    twitterTitle: <meta name="twitter:title" content="Account Settings — Expo" />,
    facebookTitle: <meta property="og:title" content="Account Settings — Expo" />,
    description: <meta name="description" content="Update your settings." />,
    twitterDescription: <meta name="twitter:description" content="Update your settings." />,
    facebookDescription: <meta property="og:description" content="Update your settings." />,
  }),
  newsletter: ({ newsletterMetadata }: Props) => {
    const image = newsletterMetadata?.image ?? defaultAppIcon;
    return {
      ...defaultMetaTags,
      title: <title>{newsletterMetadata?.title}</title>,
      twitterTitle: <meta name="twitter:title" content={newsletterMetadata?.title} />,
      facebookTitle: <meta property="og:title" content={newsletterMetadata?.title} />,
      description: <meta name="description" content={newsletterMetadata?.description} />,
      twitterDescription: (
        <meta name="twitter:description" content={newsletterMetadata?.description} />
      ),
      facebookDescription: (
        <meta property="og:description" content={newsletterMetadata?.description} />
      ),
      image: <meta property="og:image" content={image} />,
      imageURL: <meta property="og:image:url" content={image} />,
      imageSecureURL: <meta property="og:image:secure_url" content={image} />,
      twitterImage: <meta name="twitter:image" content={image} />,
    };
  },
  profileindex: (props: Props) => {
    const name = getUserDisplayName(props.user!);

    const profilePhoto = 'profilePhoto' in props.user! ? props.user.profilePhoto : null;
    const profilePhotoTags = profilePhoto
      ? {
          image: <meta property="og:image" content={profilePhoto} />,
          imageURL: <meta property="og:image:url" content={profilePhoto} />,
          imageSecureURL: <meta property="og:image:secure_url" content={profilePhoto} />,
          twitterImage: <meta name="twitter:image" content={profilePhoto} />,
        }
      : {};

    return {
      title: <title>{`${name} on Expo`}</title>,
      twitterTitle: <meta name="twitter:title" content={`${name} on Expo`} />,
      facebookTitle: <meta property="og:title" content={`${name} on Expo`} />,
      description: <meta name="description" content={`${name}'s projects on Expo`} />,
      twitterDescription: (
        <meta name="twitter:description" content={`${name}'s projects on Expo`} />
      ),
      facebookDescription: (
        <meta property="og:description" content={`${name}'s projects on Expo`} />
      ),
      ...profilePhotoTags,
    };
  },
  profilesnacks: (props: Props) => {
    const name = getUserDisplayName(props.user!);

    const profilePhoto = 'profilePhoto' in props.user! ? props.user.profilePhoto : null;
    const profilePhotoTags = profilePhoto
      ? {
          image: <meta property="og:image" content={profilePhoto} />,
          imageURL: <meta property="og:image:url" content={profilePhoto} />,
          imageSecureURL: <meta property="og:image:secure_url" content={profilePhoto} />,
          twitterImage: <meta name="twitter:image" content={profilePhoto} />,
        }
      : {};

    return {
      title: <title>{`Snacks by ${name} — Expo`}</title>,
      twitterTitle: <meta name="twitter:title" content={`Snacks by ${name} — Expo`} />,
      facebookTitle: <meta property="og:title" content={`Snacks by ${name} — Expo`} />,
      description: <meta name="description" content={`Snacks by ${name} on Expo`} />,
      twitterDescription: (
        <meta name="twitter:description" content={`Snacks created by ${name} on Expo`} />
      ),
      facebookDescription: (
        <meta property="og:description" content={`Snacks created by ${name} on Expo`} />
      ),
      ...profilePhotoTags,
    };
  },
  project: (props: Props) => {
    const icon = 'icon' in props.project! ? props.project.icon : null;
    const iconTags = icon
      ? {
          appIcon: <link rel="apple-touch-icon" href={icon.url} />,
          image: <meta property="og:image" content={icon.url} />,
          imageURL: <meta property="og:image:url" content={icon.url} />,
          imageSecureURL: <meta property="og:image:secure_url" content={icon.url} />,
          twitterImage: <meta name="twitter:image" content={icon.url} />,
          msImage: <meta name="msapplication-TileImage" content={icon.url} />,
        }
      : {};

    return {
      title: <title>{`${props.project!.name} on Expo`}</title>,
      twitterTitle: <meta name="twitter:title" content={`${props.project!.name} on Expo`} />,
      facebookTitle: <meta property="og:title" content={`${props.project!.name} on Expo`} />,
      ...iconTags,
    };
  },
  notificationsdashboard: () => {
    return {
      ...defaultMetaTags,
      title: <title>Push Notification Tool — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Push Notification Tool — Expo" />,
      facebookTitle: <meta property="og:title" content="Push Notification Tool — Expo" />,
      description: (
        <meta name="description" content="Test sending push notifications to your device." />
      ),
      twitterDescription: (
        <meta
          name="twitter:description"
          content="Test sending push notifications to your device."
        />
      ),
      facebookDescription: (
        <meta property="og:description" content="Test sending push notifications to your device." />
      ),
    };
  },
  eas: () => {
    const image = Utilities.getAssetUrl('/static/share/eas.jpg');
    return {
      ...defaultMetaTags,
      title: <title>Expo Application Services (EAS)</title>,
      twitterTitle: <meta name="twitter:title" content="Expo Application Services (EAS)" />,
      facebookTitle: <meta property="og:title" content="Expo Application Services (EAS)" />,
      description: (
        <meta
          name="description"
          content="Deeply integrated cloud services for Expo and React Native apps, from the team behind Expo."
        />
      ),
      twitterDescription: (
        <meta
          name="twitter:description"
          content="Deeply integrated cloud services for Expo and React Native apps, from the team behind Expo."
        />
      ),
      facebookDescription: (
        <meta
          property="og:description"
          content="Deeply integrated cloud services for Expo and React Native apps, from the team behind Expo."
        />
      ),
      image: <meta property="og:image" content={image} />,
      imageURL: <meta property="og:image:url" content={image} />,
      imageSecureURL: <meta property="og:image:secure_url" content={image} />,
      twitterImage: <meta name="twitter:image" content={image} />,
    };
  },
  easFairUse: () => {
    return {
      ...defaultMetaTags,
      title: <title>EAS - Fair Use Policy</title>,
    };
  },
  easEnterpriseSupport: () => {
    return {
      ...defaultMetaTags,
      title: <title>EAS - Enterprise Support</title>,
    };
  },
  easSLA: () => {
    return {
      ...defaultMetaTags,
      title: <title>EAS - Service Level Agreement</title>,
    };
  },
  registerDevice: () => {
    return {
      ...defaultMetaTags,
      title: <title>Register iOS Device — Expo</title>,
    };
  },
  invitation: () => {
    return {
      ...defaultMetaTags,
      title: <title>Invitation — Expo</title>,
    };
  },
  internal: (props: Props) => {
    return {
      ...defaultMetaTags,
      title: <title>{`${props.pageName ?? 'Internal Tools'} — Expo`}</title>,
    };
  },
  'expo-go': () => {
    const image = Utilities.getAssetUrl('/static/share/client.jpg');
    return {
      ...defaultMetaTags,
      title: <title>Expo Go — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Expo Go — Expo" />,
      facebookTitle: <meta property="og:title" content="Expo Go — Expo" />,
      image: <meta property="og:image" content={image} />,
      imageURL: <meta property="og:image:url" content={image} />,
      imageSecureURL: <meta property="og:image:secure_url" content={image} />,
      twitterImage: <meta name="twitter:image" content={image} />,
    };
  },
  customers: () => {
    return {
      ...defaultMetaTags,
      title: <title>Customers — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Customers — Expo" />,
      facebookTitle: <meta property="og:title" content="Customers — Expo" />,
    };
  },
  customerTestimonial: () => {
    return {};
  },
  changelog: () => {
    const image = Utilities.getAssetUrl('/static/share/changelog.jpg');

    return {
      ...defaultMetaTags,
      title: <title>Changelog — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Changelog — Expo" />,
      facebookTitle: <meta property="og:title" content="Changelog — Expo" />,
      description: (
        <meta name="description" content="Check out new updates from Expo on the Expo changelog." />
      ),
      twitterDescription: (
        <meta
          name="twitter:description"
          content="Check out new updates from Expo on the Expo changelog."
        />
      ),
      facebookDescription: (
        <meta
          property="og:description"
          content="Check out new updates from Expo on the Expo changelog."
        />
      ),
      image: <meta property="og:image" content={image} />,
      imageURL: <meta property="og:image:url" content={image} />,
      imageSecureURL: <meta property="og:image:secure_url" content={image} />,
      twitterImage: <meta name="twitter:image" content={image} />,
    };
  },
  changelogDetail: ({ changelogMetadata }: Props) => {
    const title = `${changelogMetadata?.title} - Expo Changelog`;

    const image = changelogMetadata?.image
      ? Utilities.getAssetUrl(changelogMetadata?.image)
      : Utilities.getAssetUrl('/static/images/blog-placeholder.webp');

    const description =
      changelogMetadata?.description ?? 'Check out new updates from Expo on the Expo changelog.';

    return {
      ...defaultMetaTags,
      title: <title>{title}</title>,
      twitterTitle: <meta name="twitter:title" content={title} />,
      facebookTitle: <meta property="og:title" content={title} />,
      description: <meta name="description" content={description} />,
      twitterDescription: <meta name="twitter:description" content={description} />,
      facebookDescription: <meta property="og:description" content={description} />,
      appIcon: <link rel="apple-touch-icon" href={defaultAppIcon} />,
      image: <meta property="og:image" content={image} />,
      imageURL: <meta property="og:image:url" content={image} />,
      imageSecureURL: <meta property="og:image:secure_url" content={image} />,
      twitterImage: <meta name="twitter:image" content={image} />,
      author: changelogMetadata?.author ? (
        <meta property="article:author" content={changelogMetadata.author} />
      ) : undefined,
      date: changelogMetadata?.date ? (
        <meta property="article:published_time" content={changelogMetadata.date} />
      ) : undefined,
    };
  },
  orbitLanding: () => {
    const image = Utilities.getAssetUrl('/static/share/orbit.jpg');
    return {
      ...defaultMetaTags,
      title: <title>Expo Orbit — Expo</title>,
      twitterTitle: <meta name="twitter:title" content="Expo Orbit — Expo" />,
      facebookTitle: <meta property="og:title" content="Expo Orbit — Expo" />,
      description: (
        <meta
          name="description"
          content="Accelerate your development workflow with one-click application launches and simulator management."
        />
      ),
      twitterDescription: (
        <meta
          name="twitter:description"
          content="Accelerate your development workflow with one-click build launches and simulator management."
        />
      ),
      facebookDescription: (
        <meta
          property="og:description"
          content="Accelerate your development workflow with one-click build launches and simulator management."
        />
      ),
      image: <meta property="og:image" content={image} />,
      imageURL: <meta property="og:image:url" content={image} />,
      imageSecureURL: <meta property="og:image:secure_url" content={image} />,
      twitterImage: <meta name="twitter:image" content={image} />,
    };
  },
  expoGo: ({ pageName }: Props) => {
    const image = Utilities.getAssetUrl('/static/share/client.jpg');
    return {
      ...defaultMetaTags,
      title: <title>{pageName}</title>,
      twitterTitle: <meta name="twitter:title" content={pageName} />,
      facebookTitle: <meta property="og:title" content={pageName} />,
      description: (
        <meta name="description" content="Install past and current versions of Expo Go." />
      ),
      twitterDescription: (
        <meta name="twitter:description" content="Install past and current versions of Expo Go." />
      ),
      facebookDescription: (
        <meta property="og:description" content="Install past and current versions of Expo Go." />
      ),
      image: <meta property="og:image" content={image} />,
      imageURL: <meta property="og:image:url" content={image} />,
      imageSecureURL: <meta property="og:image:secure_url" content={image} />,
      twitterImage: <meta name="twitter:image" content={image} />,
    };
  },
  onboarding: ({ pageName }: Props) => {
    const image = Utilities.getAssetUrl('/static/share/onboarding.jpg');

    const title = `Onboarding — ${pageName}`;
    const description = 'Get started developing applications in record time with Expo.';

    return {
      ...defaultMetaTags,
      title: <title>{title}</title>,
      twitterTitle: <meta name="twitter:title" content={title} />,
      facebookTitle: <meta property="og:title" content={title} />,
      description: <meta name="description" content={description} />,
      twitterDescription: <meta name="twitter:description" content={description} />,
      facebookDescription: <meta property="og:description" content={description} />,
      image: <meta property="og:image" content={image} />,
      imageURL: <meta property="og:image:url" content={image} />,
      imageSecureURL: <meta property="og:image:secure_url" content={image} />,
      twitterImage: <meta name="twitter:image" content={image} />,
    };
  },
};

export type PagesWithMetatags = keyof typeof _copy;

// I've reassigned the export here to make sure that the keys above are always in sync with the values.
export const Copy = _copy as Record<string, any>;
